import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/legal-notice/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/legal-notice/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/aviso-legal/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/aviso_legal/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/legal-notice/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/legal-notice/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>Aviso Legal</h2>
                <p>
                  Ao aceder e consultar este site concorda sem restrições com as
                  seguintes condições de uso:
                </p>
                <p>
                  <strong>Todo o conteúdo</strong> deste site é propriedade e é
                  controlado pela JNTL Consumer Health (Portugal) Limitada e está
                  protegido pelas leis internacionais do copyright. Pode fazer
                  download do conteúdo apenas para seu uso pessoal para fins não
                  comerciais, mas não são permitidas a modificação ou posterior
                  reprodução do conteúdo. De outro modo, o conteúdo não pode ser
                  copiado ou usado de qualquer forma.
                </p>
                <p>
                  <strong>Os proprietários</strong> deste site farão os
                  possíveis para incluir informação atualizada e exata no mesmo,
                  mas não apresentam declarações, garantias ou certezas quanto à
                  exatidão, aceitação, ou integridade da informação
                  disponibilizada. Os proprietários deste site não poderão ser
                  responsabilizados por quaisquer danos ou prejuízos resultantes
                  do seu acesso, ou incapacidade de acesso, a este site da
                  Internet, ou da sua dependência em relação a qualquer
                  informação fornecida neste site da Internet.{" "}
                </p>
                <p>
                  <strong>Público Alvo:</strong> Este site destina-se a
                  residentes de Portugal.
                </p>
                <p>
                  <strong>Este site da Internet</strong> pode apresentar links
                  ou referências a outros sites mas os proprietários deste site
                  não têm qualquer responsabilidade pelo conteúdo de sites de
                  Terceiros e não poderão ser responsabilizados por quaisquer
                  danos ou prejuízos resultantes desses conteúdos. Quaisquer
                  links para outros sites são apresentados meramente como uma
                  comodidade para os utilizadores deste site.
                </p>
                <p>
                  <strong>As marcas,</strong> marcas de serviços, nomes
                  comerciais, identidade visual e produtos apresentados neste
                  site da Internet estão protegidos em Portugal e
                  internacionalmente. Não pode ser feito nenhum uso de qualquer
                  um destes sem a prévia autorização, por escrito, dos
                  proprietários deste site, exceto para identificar os produtos
                  ou serviços da empresa.
                </p>
                <p>
                  <strong>Qualquer informação pessoal</strong> identificável em
                  comunicações eletrónicas para este site é regulada pela{" "}
                  <a
                    title="Política de Privacidade"
                    href="/pt/legal/politica_de_privacidade/"
                  >
                    Política de Privacidade
                  </a>{" "}
                  do mesmo. Os proprietários deste site serão livres de usar ou
                  copiar todas as outras informações em tais comunicações,
                  incluindo quaisquer ideias, invenções, conceitos, técnicas ou
                  conhecimentos ali divulgados, para quaisquer fins. Tais fins
                  podem incluir a divulgação a terceiros e/ ou o
                  desenvolvimento, fabrico e/ ou a comercialização de bens e
                  serviços.
                </p>
                <p>
                  O remetente de quaisquer comunicações para este site da
                  Internet ou, de outro modo, para os proprietários deste site
                  será responsável pelo conteúdo e informações ali contidas,
                  incluindo a sua autenticidade e exatidão.
                </p>
                <p>
                  O conteúdo deste site destina-se a ser um serviço para os seus
                  visitantes. Em qualquer altura a JNTL Consumer Health (Portugal) Limitada será livre de corrigir este site sem aviso prévio.
                </p>
                <p> </p>
                <p>
                  <strong>Informação sobre a empresa:</strong>
                </p>
                <p>
                  JNTL Consumer Health (Portugal) Limitada
                  <br />
                  <span
                    style={{
                      "font-family": "Verdana"
                    }}
                  >
                    Lagoas Park, Edifício 9. <br />
                  </span>
                  <span
                    style={{
                      "font-family": "Verdana"
                    }}
                  >
                    2740-262 PORTO SALVO
                    <br />
                  </span>
                  <span style={{}}>Serviço de Informação: 214368698</span>
                </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
